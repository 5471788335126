import axios from "axios";
import queryString from "query-string";

// let requestDebugger = {
//   timeout: 0,
//   all: [],
//   successful: [],
//   denied: [],
//   counter: 0,
// };
/** @constant {string} */
export const apiUrl = "https://smartwies.pl/rest/v2/";

/**
 * get ApiCalls
 * @async
 * @function fetchApi
 * @param {Object} params - object passed as argument
 * @param {string} params.method - "GET" | "POST" | "PATCH" | "PUT";
 * @param {string} params.url - Url - without domain
 * @param {string} params.origin - Not required; domain - if is different than topagrar.pl/rest domain
 * @param {object} params.data - Any data (params) for axios request
 * @param {object} params.token - user token (if is logged and token is needed). to remove token from request - pass token: undefined
 *
 *
 */
export const fetchApi = async (params) => {
  // requestDebugger.counter += 1;
  // requestDebugger.all.push(params);
  const origin = typeof params.origin === "string" ? params.origin : apiUrl;
  try {
    const token = params.hasOwnProperty("token")
      ? params.token
      : process.env.token;

    const response = await axios({
      ...params,
      method: params.method || "GET",
      url: origin + params.url,
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Token ${token}` : token,
      },
      data: params.data,

      timeout: 8000,
    });
    const { data, status } = response;
    // requestDebugger.successful.push(params);

    return [data, null, status];
  } catch (err) {
    // requestDebugger.denied.push(params);

    // axios timeout
    if (err.code === "ECONNABORTED") {
      return [null, { detail: err.config, error_code: err.message }, 408];
    }
    const status = err.response
      ? err.response.status
      : err.request
      ? err.request.status
      : undefined;
    const error =
      err.response && typeof err.response.data === "object"
        ? err.response.data
        : {};
    return [null, error, { status }];
  }
  // finally {
  //   clearTimeout(requestDebugger.timeout);
  //   requestDebugger.timeout = setTimeout(() => {
  //     console.log("Backend data requests:", requestDebugger);
  //   }, 5000);
  // }
};

export const buildArrayWithOffsetValues = (count, limit) => {
  const offsets = [0];
  while (offsets[offsets.length - 1] + limit <= count) {
    const lastOffset = offsets.slice(-1)[0];
    offsets.push(lastOffset + limit);
  }
  return offsets;
};

export const getHomepageRows = async () => {
  const [homepageRowsData] = await fetchApi({ url: `homepage-row/?site=12` });
  return homepageRowsData ? homepageRowsData.results : [];
};

export const getArticles = async (params) => {
  const defaultParams = {
    site: 12,
    visible_on: "site",
    ordering: "-published_at",
  };
  const query = queryString.stringify({ ...defaultParams, ...params });
  return fetchApi({ url: `articles-article/?${query}` });
};

export const getArticlesById = async (idArray) => {
  const promises = idArray.map(async (id) =>
    fetchApi({ url: `articles-article/${id}` })
  );
  const data = await Promise.all(promises);
  return data;
};

export const getArticlesOnList = async (params) => {
  const [articles] = await getArticles(params);
  if (articles) {
    const idArray = articles.results.map((article) => article.id);
    const articlesData = await getArticlesById(idArray);
    return articlesData;
  } else {
    return [];
  }
};

export const getHomepageRowsArticles = async (homepageRows) => {
  const homepageArticlesPromises = homepageRows.map(async (row) =>
    getArticles({ limit: 5, category: row.category, site: "" })
  );
  const homepageSectionArticles = await Promise.all(homepageArticlesPromises);

  return homepageSectionArticles;
};

export const getFrequentArticles = async (params) => {
  const defaultParams = {
    site: 12,
  };
  const query = queryString.stringify({ ...defaultParams, ...params });
  const [listData, err, status] = await fetchApi({
    url: `articles-frequent-articles/?${query}`,
  });
  if (listData && listData.count) {
    const idList = listData.results[0].articles;
    const preparedList = params.limit ? idList.slice(0, params.limit) : idList;
    const list = await getArticlesById(preparedList);
    return list;
  } else {
    return [];
  }
};

export const getMainCategories = async () => {
  const categoriesQuery = queryString.stringify({
    site: 12,
    only_main: "True",
    limit: 12,
  });
  const categories = await fetchApi({
    url: `articles-category/?${categoriesQuery}`,
  });
  return categories;
};

export const getArticleCategories = async ({ slug = "" }) => {
  return fetchApi({
    url: `articles-category/?slug=${slug}`,
  });
};

export const getJournalists = (search = "") => {
  return fetchApi({
    url: `contact-journalists/${search}`,
  });
};

export const getJournalistsByContactCategory = async (contactCategory) => {
  const getJournalistsPromises = (arr) => {
    const promises = arr
      .map((el) => el.journalists)
      .flat()
      .map((pk) => fetchApi({ url: `contact-journalists/${pk}` }));
    return promises;
  };
  const journalistsPromises = getJournalistsPromises(contactCategory);
  const data = await Promise.all(journalistsPromises);
  const convertedData = data.map((resp) => (resp[0] ? resp[0] : {}));
  return convertedData;
};

export const getJournalist = async (id) => {
  const [payload, error] = await fetchApi({
    url: `contact-journalists/${id}`,
  });
  if (payload) {
    return [payload || null, error];
  }
  return [null, error];
};

export const getArticleAuthors = async (idArray) => {
  const promisses = idArray.map((id) =>
    fetchApi({ url: `contact-journalists/${id.pk}` })
  );
  const authorsData = await Promise.all(promisses);
  return authorsData;
};
export const loginUser = async (requestData) => {
  const [data, error, status] = await fetchApi({
    method: "POST",
    url: "api-token-auth/",
    data: requestData,
    token: undefined,
  });
  if (data) {
    const { token } = data;
    const settingCookie = await axios({
      method: "POST",
      url: "/api/auth",
      data: { token },
      headers: {
        Authorization: `Token ${process.env.token}`,
      },
    });
    if (settingCookie.status === 200) {
      return [{ token }, null, { status: 200 }];
    }
  }
  if (error) {
    return [null, error, status];
  }
  return [null, { err: "server error" }, { status: 500 }];
};

export const logoutUser = async () => {
  const settingCookie = await axios({
    method: "POST",
    url: "/api/auth",
    data: { token: "" },
    headers: {
      Authorization: `Token ${process.env.token}`,
    },
  });
  if (settingCookie.status === 200) {
    return [settingCookie.data, null, { status: 200 }];
  }

  return [null, { err: "bad request" }, { status: 400 }];
};

export const patchUserData = (data) =>
  fetchApi({
    method: "PATCH",
    url: "auth-update-profile/",
    data,
  });

// export const changePassword = async (data) => {
//   try {
//     const resp = await axios({
//       method: "PUT",
//       url: "/api/change-password",
//       headers: {
//         Authorization: `Token ${process.env.token}`,
//       },
//       data,
//     });
//     if (resp.status === 200) {
//       return [...resp.data.data];
//     }
//   } catch (err) {
//     return [null, { err: "bad request" }, { status: 500 }];
//   }
// };

export const authRegister = (data) =>
  fetchApi({
    method: "POST",
    url: "auth-register/",
    data: {
      ...data,
      site: process.env.SITE_ID || 12,
    }
  });

export const newsletterSubscribe = (data) =>
  fetchApi({
    method: "POST",
    url: "newsletter-subscribe/",
    data,
  });

export const sendContactForm = (data) =>
  fetchApi({
    method: "POST",
    url: "contact-form/",
    data,
  });

export const forgotPasswordRequest = (data) =>
  fetchApi({
    method: "POST",
    url: "auth-password-forgot-email/",
    data,
  });

export const forgotPasswordVerify = (data) =>
  fetchApi({
    method: "POST",
    url: "auth-password-forgot-verify/",
    data,
  });

export const forgotPasswordSetNew = (data) =>
  fetchApi({
    method: "POST",
    url: "auth-password-forgot-form/",
    data,
  });

export const activateAccount = ({key}) => 
  fetchApi({
    method: "POST",
    url: "auth-user-activation/",
    data: {
      site: process.env.SITE_ID || 12,
      key,
    },
  });

export const changePassword = (data, token) => {
  if (!token) {
    throw new Error("You must pass user's token to change password!");
  }
  if (token === process.env.token) {
    throw new Error(
      "You have passed wrong token - expected user's token, got fake user's token (rest user)"
    );
  }
  return fetchApi({
    method: "PUT",
    url: "/auth-password-reset/",
    data,
    token,
  });
};
