import { useState, useEffect } from "react";
import { fetchApi } from "../services/requests";

export function useFrequentArticlesRequest(limit) {
  let [idArticles, setIdArticles] = useState([]);
  let [articles, setArticles] = useState([]);
  let [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi({
        url: `articles-frequent-articles/?site=12`,
      });
      if (data[0]) {
        const { results } = data[0];
        if (results.length) {
          setIdArticles(results[0].articles.splice(0, limit));
        }
      }
      if (data[1]) {
        setError(data[1]);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      const promisses = idArticles.map((id) =>
        fetchApi({ url: `articles-article/${id}` })
      );
      const data = await Promise.all(promisses);
      const newData = data.map((el) => el[0]).filter(Boolean);

      setArticles(newData);
    };
    getData();
  }, [idArticles]);

  return [articles, error];
}
