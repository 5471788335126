import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  padding-top: 70px;
  margin-bottom: 20px;
`;
export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  top: 0;
  z-index: 100;
  position: fixed;
  border-bottom: ${(props) =>
    props.isActive ? "1px solid rgba(255,255,255,0.26)" : "1px solid #e6e6e6"};
  background-color: ${(props) => (props.isActive ? "#1D3A8D" : "#fff")};
`;

export const NavRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Logo = styled.img`
  max-width: 120px;
  max-height: 70px;
  width: auto;
  margin-left: 20px;
  align-self: center;
`;

export const Icon = styled.span`
  padding: 20px;
  margin-left: 10px;
  display:flex;
`;

export const IconWithAfter = styled(Icon)`
  padding: 20px;
  margin-left: 10px;
  &::after {
    content: "";
    display: inline-block;
    position: relative;
    left: 20px;
    top: 8px;
    width: 1px;
    height: 20px;
    background-color: #e6e6e6;
  }
`;

export const ListDropdown = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 70px);
  background-color: #3f579d;
  z-index: 100;
`;
export const NavDropdown = styled.nav`
  position: fixed;
  width: 100%;
  height: calc(100% - 70px);
  background-color: #3f579d;
  z-index: 1000;
`;

export const Circles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: radial-gradient(farthest-side, #556aa7 100%, transparent) 45vw
      15vh/360px 360px,
    radial-gradient(farthest-side, #556aa7 100%, transparent) -300px 60vh/600px 600px,
    radial-gradient(farthest-side, #556aa7 100%, transparent) 80vw 75vh/60px
      60px;
  background-repeat: no-repeat;
  overflow: scroll;
`;

export const PartnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
`;

export const PartnerText = styled.span`
  font-size: 8px;
  line-height: 10px;
  color: ${({ isActive }) => (isActive ? "#fff" : "#000")};
  margin: 6px 0 0 0;
`;

export const PartnerLogo = styled.img`
  max-width: 100%;
  display: ${({ isActive }) => (isActive ? "block" : "none")};
`;
