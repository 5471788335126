import { Checkbox } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { fetchApi } from "../../services/requests";

const Row = styled.div`
  max-width: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1240px) {
    margin: 0 auto;
  }
`;

const Icon = styled.img`
  display: inline-block;
  max-width: 32px;
  max-height: 32px;
  margin: 0 10px;
`;

const SocialIcons = ({ containerStyle, position }) => {
  const [icons, setIcons] = useState([]);
  const isMount = useRef(true);

 

  useEffect(() => {
    const getIcons = async () => {
      const [icons] = await fetchApi({ url: "blocks-social-link/?site=12" });
      icons && isMount.current && setIcons(icons.results);
    };
    getIcons();
  }, []);

  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);



	
  return (
    <>
      {position === 'footer' && (
        icons.map((icon) => 
            (
              icon.name.includes('stopka') && (
                <a href={icon.link} target="_blank" key={icon.name}>
                  <Icon src={icon.image} />
                </a>
              )
            )
      ))}

      {!position && (
        <Row style={containerStyle}>
          {icons.map((icon) => 
            (
              !icon.name.includes('stopka') && (
                <a href={icon.link} target="_blank" key={icon.name}>
                  <Icon src={icon.image} />
                </a>
              )
            ))
          }
        </Row>
      )}
    </>
  );
};

export default SocialIcons;
