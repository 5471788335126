import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

  border-bottom: 20px solid #f5f5f5;
  width: 100%;
  @media ${device.desktop} {
    border-bottom: 40px solid #f5f5f5;
    padding-bottom: 3 0px;
  }
  @media print {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1020px;
  width: 100%;
`;

export const Header = styled.p`
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  font-weight: 900;
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 32px;
  }
`;
