import styled from "styled-components";
import { device } from "../../services/styles";

export const Footer = styled.footer`
  width:100%;
  display: flex;
  flex-direction:column;
  font-family:'Pt-Sans';
  margin-top: 20px;
  background-color:#F5F5F5;

`;

export const FooterLink = styled.a`
`

export const NavContainer = styled.div`
      width:calc(100% - 40px);
      margin:auto;
`;

export const Nav = styled.div`
      padding: 40px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 40px;
      margin: auto;
      max-width: 1100px;
      width:100%;
      margin: auto;
`;

export const Column = styled.div`
      margin: 0 0 40px 0;
      width: 180px;
     
`;

export const Title = styled.h5`
  color:#555555;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 10px 0;
  font-weight: bold;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type:none;
`;

export const Item = styled.li`
`;

export const Link = styled.a`
  color:#888888;
  font-size:14px;
  &:hover{
    text-decoration:underline;
  }
`;

export const LinkText = styled.span`
  color:#888888;
  font-size:14px;
  line-height:18px;
  display:inline-block;
  &:hover{
    text-decoration:underline;
  }
`;

export const LinkProduct = styled.a`
  color:#888888;
  font-size:14px;
  line-height:18px;
  display:inline-block;
  vertical-align:middle;
  height:18px;
`;

export const TextProduct = styled(LinkText)`
  display:inline-block;
  width:180px;
  line-height:18px;  
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  
`;

export const SocialLink = styled.a`
  color:#888888;
  font-size:14px;
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  width:150px;
`
export const Image = styled.img`
  `

export const Portfolio = styled.div`
  background-color:#fff;
`
export const PortfolioContainer = styled.div`
  max-width:1280px;
  margin:auto;
`
export const PortfolioRow = styled.div`

`
export const PortfolioList = styled.ul`
  display: flex;
  list-style-type:none;
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
`
export const PortoflioItem = styled.li`
`

export const LinkPortfolio = styled.a`
  margin:0;
`

export const PortfolioImageBox = styled.figure`
  margin:0;
  @media(max-width:576px){
    max-width:100px;
  }
`

export const PortfolioImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Copyrights = styled.div`
  border-top: 2px solid #F5F5F5;
  background-color:#fff;
  padding: 20px 0;
`

export const CopyrightsText = styled.p`
  max-width:1200px;
  padding: 0 20px;
  margin:auto;
  font-size:12px;
  text-align:center;
  color:#888888;
`