import React from "react";
const MetaTags = ({ description, keywords, href, showAuthor, activePath }) => {
  const descriptionData = activePath?.description || description;
  const keywordsData = activePath?.keywords || keywords;

  return (
    <>
      {showAuthor && <meta name="author" content="agrarlex.pl" />}
      <meta
        name="description"
        content={descriptionData || "Portal agrarlex.pl"}
      />
      {keywordsData && <meta name="keywords" content={keywordsData} />}
      {href && <link rel="canonical" href={href} />}
    </>
  );
};

export default MetaTags;
