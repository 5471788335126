import React from "react";
import Link from "next/link";

import * as S from "./Footer.sc";

const LinkList = ({ list }) => {
  return (
    <S.List>
      {list.map((el) => (
        <li key={`footer${el.id}`}>

          {el.url && (<Link href={el.url} passHref={true}>
            <S.Link title={el.label}>
              <S.LinkText>{el.label}</S.LinkText>
            </S.Link>
          </Link>)}

        </li>
      ))}
    </S.List>
  );
};

const CategoryList = ({ list }) => {
  return (
    <S.List>
      {list.map((el) => (
              <li key={`footer${el.id}`}>

                {el.url && (<Link href={`/artykuly${el.url}`} passHref={true}>
                  <S.Link title={el.label}>
                    <S.LinkText>{el.label}</S.LinkText>
                  </S.Link>
                </Link>)}

              </li>
            ))}
    </S.List>
  )
}

const ProductList = ({ list }) => {
  return (
    <S.List>
      {list.map((el) => 
        <li key={`footer${el.agrarsklepid}`}>
            {el.name && (
              <S.LinkProduct href={el.link} title={el.name} target="_blank">
              <S.TextProduct>{el.name}</S.TextProduct>
            </S.LinkProduct>
            )}
        </li>
      )}
    </S.List>
  )
}

const PortfolioList = ({ list }) => {
  return (
    <S.PortfolioList>
      {list.map((el) => (
        <li key={`portfolio${el.title}`}>
          {el.title &&  (
            <S.LinkPortfolio href={el.url} title={el.title} target="_blank">
              <S.PortfolioImageBox>
                <S.PortfolioImage src={`${el.img}`} />
              </S.PortfolioImageBox>
            </S.LinkPortfolio>
          )}
        </li>
      ))}
    </S.PortfolioList>
  );
};

export  {LinkList, CategoryList, ProductList, PortfolioList};
