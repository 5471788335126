import React, { useState, useEffect, createContext } from "react";
import { fetchApi } from "../services/requests";

const MenuListContext = createContext([{}, () => {}]);

const MenuListProvider = (props) => {
  const { links, submenuLists } = props.menuList;
  return (
    <MenuListContext.Provider value={{ list: links, submenuLists }}>
      {props.children}
    </MenuListContext.Provider>
  );
};

export { MenuListContext, MenuListProvider };
