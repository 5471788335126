import React from "react";
import {LinkList, CategoryList, ProductList, PortfolioList} from "./LinkList";
import Link from "next/link";

import ArticlesList from "./ArticlesList";
import SocialIcons from "../SocialIcons/SocialIcons";
import { useLinkListRequest } from "../../hooks/useLinkListRequest";
import { useLinkListProductsRequest } from "../../hooks/useLinkListProductsRequest";
import { useFooterPortfolioRequest } from "../../hooks/useFooterPortfolioRequest";


import { useArticlesRequest } from "../../hooks/useArticlesRequest";
import { useFrequentArticlesRequest } from "../../hooks/useFrequentArticlesRequest";
import * as S from "./Footer.sc";

const Footer = () => {
  const [list] = useLinkListRequest(43);
  const [list2] = useLinkListRequest(44);
  const [list3] = useLinkListRequest(49);


  // const [cat] = useLinkListCategoryRequest();
  const [products] = useLinkListProductsRequest();
  const [services] = useFooterPortfolioRequest();
  const [importantArticles] = useArticlesRequest({
    important: "True",
    limit: 3,
    offset: 10,
  });
  const [frequentArticles] = useFrequentArticlesRequest(3);
  return (

    
    <S.Footer>

      <S.NavContainer>
        <S.Nav>
          <S.Column>
            <S.Title>Pytania i regulaminy</S.Title>
            {list && <LinkList list={list}></LinkList>}
          </S.Column>
        

        <S.Column>
          <S.Title>Kategorie</S.Title>
          {list2 && <CategoryList list={list2}></CategoryList>}

        </S.Column>


        <S.Column>
          <S.Title>Produkty Agrarsklep</S.Title>
          {products && <ProductList list={products}></ProductList>}
        </S.Column>

        <S.Column>
          <S.Title>Przydatne linki</S.Title>
          {list3 && <LinkList list={list3}></LinkList>}
        </S.Column>

        <S.Column>
          <S.Title>Social Media</S.Title>
          <S.SocialMedia>
              <SocialIcons position="footer"/>
          </S.SocialMedia>
        </S.Column>

        </S.Nav>
      </S.NavContainer>

      <S.Portfolio>
        <S.PortfolioContainer>
          <S.PortfolioRow>
            {services && <PortfolioList list={services}></PortfolioList>}
          </S.PortfolioRow>
        </S.PortfolioContainer>
      </S.Portfolio>

      <S.Copyrights>
        <S.CopyrightsText>
          <b> Polskie Wydawnictwo Rolnicze Sp. z o.o., ul. Metalowa 5, 60-118 Poznań. Akta rejestrowe przechowywane w Sądzie Rejonowym Poznań - Nowe Miasto i Wilda w Poznaniu, VIII Wydziale Gospodarczym, KRS 0000101146, NIP 7780164903, REGON 630175513, kapitał zakładowy: 1.000.000 PLN. </b>
          <br /><br />
          Wszystkie prezentowane w ramach niniejszego portalu treści są własnością Polskiego Wydawnictwa Rolniczego Sp. z o.o., są zastrzeżone i chronione prawem autorskim, kopiowanie i dalsze rozpowszechnianie treści jest zabronione. (art. 25 ust. 1 pkt 1b ustawy z 4 lutego 1994 roku o prawie autorskim i prawach pokrewnych) 
        </S.CopyrightsText>
      </S.Copyrights>

    </S.Footer>
  );
};

export default Footer;
