import { useState, useEffect } from "react";
import { fetchApi } from "../services/requests";

export function useLinkListRequest(id) {
  let [list, setList] = useState(null);
  let [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi({ url: `pages-link-list/${id}` });
      if (data[0]) {
        setList(data[0].links);
      }
      if (data[1]) {
        setError(data[1]);
      }
    };
    getData();
  }, []);

  return [list, error];
}
