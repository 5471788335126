/**
 * compose
 * @function compose
 * const compose = (f, g) => (x) => f(g(x));
 * @param {array} fns - array of functions
 *
 */
export const compose = (...fns) =>
  fns.reduceRight(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    (value) => value
  );
