import { useState, useEffect } from "react";
import { fetchApi } from "../services/requests";

export function useLinkListProductsRequest() {
  let [list, setList] = useState(null);
  let [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi({ url: `blocks-agrarsklep-product?tags=stopka-agrarlex&limit=7` });
      if (data) {
        setList(data[0].results);
      }
      if (data[0].results) {
        setError(data[0].results);
      }

    };
    getData();

  }, []);

  return [list, error];
}