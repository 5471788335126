import React, { useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import * as S from "./Layout.sc";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import ImportantArticles from "../components/ImportantArticles/ImportantArticles";
import Partners from "../components/Partners/Partners";
import Ado from "../components/Ado/Ado";
import AdoCodes from "../components/Ado/AdoConfig.json";
import MetaTags from "../components/MetaTags/MetaTags";
import { MenuListProvider } from "../context/MenuListContext";
import { MainCategoriesProvider } from "../context/MainCategoriesContext";
import TopImportantArticle from "../components/TopImportantArticle/TopImportantArticle";
import { Container } from "@material-ui/core";

const Layout = ({
  navbarLinks,
  submenuLists,
  paths,
  importantArticles,
  mainCategories,
  metatagsData,
  children,
}) => {
  const initialLinks =
    Array.isArray(navbarLinks) && navbarLinks[0] ? navbarLinks[0].links : [];
  const [links, setLinks] = useState(initialLinks);
  const articles = importantArticles ? importantArticles.results : [];
  const { asPath } = useRouter();
  const activePath =
    paths && paths[0] && paths[0].results.find((el) => el.path === asPath);
  const pageTitle =
    activePath?.title || metatagsData?.title || process.env.defaultTitle;
  return (
    <MenuListProvider menuList={{ links, submenuLists }}>
      <div className="main-container">
        <Head>
          <title>{pageTitle}</title>
          <MetaTags {...metatagsData} dataFromApi={activePath} />
        </Head>
        <MainCategoriesProvider mainCategories={mainCategories}>
          <Navbar />
        </MainCategoriesProvider>
        <div className="max-width-row">
          <S.Row>
            <S.Wideboard>
              <Ado
                {...AdoCodes.id10headerWideboard}
                  customStyle={{}
                }
              />
              
            </S.Wideboard>
            <S.Partners>
              <Ado
                {...AdoCodes.id11servicePartner}
                title={"Partnerzy portalu"}
                customStyle={{
                  // margin: "0 10px 20px 10px",
                  textAlign: "center",
                  width: 140,
                }}
              />
              <Ado
                {...AdoCodes.id12servicePartner2}
                title={""}
                customStyle={{
                  // margin: "0 10px 20px 10px",
                  width: 140,
                }}
              />
            </S.Partners>
          </S.Row>
          <TopImportantArticle topic={articles[0]} />
          {children}
        </div>
        <ImportantArticles topics={articles.slice(1)} />
        <Partners />
        <Container>
          <Ado
            {...AdoCodes.id14footer}
            customStyle={{
              margin: "70px auto 0 auto",
            }}
          />
        </Container>
        <Footer />
      </div>
    </MenuListProvider>
  );
};
export default Layout;
