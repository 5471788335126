import React from "react";
import Link from "next/link";

import * as S from "./Article.sc";
import { getRelativeArticlePath } from "../../services/urlString";
/**
 * ArticleTeaser component
 * @function ArticleTeaser
 * @param {Object} props
 * @param {Object} props.article - props - article data
 * @param {string} props.theme - "slider" | "classic";
 *
 */

const labelColors = {
  red: "#E6141B",
  green: "#339D37",
  navyBlue: "#4762AB",
  blue: "#4D75E1",
  claret: "#9A1C20",
};

const Article = ({ title, category, url }) => {
  const labelColor = labelColors.navyBlue;
  return (
    <Link
      href={"/artykuly/[category]/[article]"}
      as={getRelativeArticlePath(url)}
      passHref={true}
    >
      <a>
        <S.Container>
          {category && <S.Category color={labelColor}>{category}</S.Category>}
          <S.Title color={labelColor}>{title}</S.Title>
        </S.Container>
      </a>
    </Link>
  );
};

export default Article;
