import React from "react";
import * as S from "./NavbarMobile.sc";
import MenuList from "../MenuList/MobileMenuList";
import SearchForm from "../SearchForm/SearchForm";
import SocialIcons from "../SocialIcons/SocialIcons";

const DropdownWithLinks = () => (
  <S.NavDropdown>
    <S.Circles>
      <div>
        <SearchForm isMobile={true} />
        <MenuList isMobile={true} />
      </div>
      <SocialIcons maxWidth={"110px"} />
    </S.Circles>
  </S.NavDropdown>
);

export default DropdownWithLinks;
