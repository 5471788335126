import React from "react";
import Article from "./Article";
import * as S from "./ImportantArticles.sc";

const ImportantArticle = ({ topics }) => {
  if (topics && topics.length) {
    return (
      <S.Container>
        <S.Header>Najważniejsze tematy</S.Header>
        <S.Row>
          {topics
            .filter((topic) => topic.articles.length > 0)
            .map((topic) => (
              <Article
                key={topic.pk}
                title={topic.articles[0].title}
                category={topic.articles[0].category || ""}
                url={topic.articles[0].get_external_url}
                theme="classic"
              />
            ))}
        </S.Row>
      </S.Container>
    );
  } else {
    return null;
  }
};

export default ImportantArticle;
