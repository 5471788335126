import React, { createContext } from "react";

const MainCategoriesContext = createContext([{}, () => {}]);

const MainCategoriesProvider = (props) => {
  return (
    <MainCategoriesContext.Provider value={props.mainCategories}>
      {props.children}
    </MainCategoriesContext.Provider>
  );
};

export { MainCategoriesContext, MainCategoriesProvider };
