import styled from "styled-components";
import { device } from "../services/styles";

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media ${device.tabletLarge} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Wideboard = styled.div`
  width: 100%;
  max-width: 940px;
  @media ${device.tabletLarge} {
    width: calc(100% - 160px);
  }
`;

export const Partners = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 10px;
  @media ${device.tabletLarge} {
    justify-content: center;
    flex-direction: column;
  }
  .title--additional {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    @media ${device.tabletLarge} {
      position: static;
    }
  }
`;
