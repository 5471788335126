import React from "react";
import Link from "next/link";
// import striptags from "striptags";
import { getRelativeArticlePath } from "../../services/urlString";
import * as S from "./TopImportantArticle.sc";

const TopImportantArticle = ({ topic }) => {
  const article = topic?.articles[0];
  if (article) {
    return (
      <Link
        href={"/artykuly/[category]/[article]"}
        as={getRelativeArticlePath(article.get_external_url)}
        passHref={true}
      >
        <a>
          <S.Row>
            <S.Important>WAŻNE</S.Important>
            <S.TextRow>
              <S.Title>{article.title}</S.Title>
              {/* <S.Title>{striptags(article.lead)}</S.Title> */}
            </S.TextRow>
          </S.Row>
        </a>
      </Link>
    );
  } else {
    return null;
  }
};

export default TopImportantArticle;
