import { compose } from "./utility";

export const replaceDomainToTap = (url) => {
  if (!url) {
    return "";
  }
  const regex = new RegExp(`${process.env.site}/`);
  return url.replace(regex, "https://agrarlex.pl/");
};

export const getRelativePath = (url) => {
  if (!url) {
    return "";
  }
  const regex = new RegExp(`${process.env.site}/`);
  return url.replace(regex, "/");
};

export const getRelativePathFromTap = (url) => {
  if (!url) {
    return "";
  }
  const regex = new RegExp("https://agrarlex.pl/");
  return url.replace(regex, "/");
};

export const replaceArticlesPath = (url) => {
  if (!url) {
    return "";
  }
  const regex = new RegExp("/articles/");
  return url.replace(regex, "/artykuly/");
};

/**
 * @param {string} url
 * @example
 * https://agrarlex.pl/something -> /something
 */
const excludeOrigin = (url) => {
  const includesProtocol = ["http://", "https://"].some((protocol) =>
    url.includes(protocol)
  );
  if (includesProtocol) {
    return new URL(url).pathname;
  }
  return url;
};

export const getRelativeArticlePath = (url) =>
  compose(excludeOrigin, getRelativePath, replaceArticlesPath)(url);

export const getRelativeArticleServicesPath = (url) => {
  const slug = getArticleSlug(url);
  return `/artykuly-z-serwisow/${slug}`;
};

export const getArticleSlug = (externalUrl) => {
  const arr = externalUrl.split("/").filter((el) => el !== "");
  return arr[arr.length - 1];
};

export const removeQueriesFromPath = (url) => {
  if (!url) {
    return "";
  }
  const regExp = /\?.*/g;
  return url.replace(regExp, "");
};

export const buildSlug = (string) => {
  return string
    .toString()
    .toLowerCase()
    .replace(/(\w)\'/g, "$1") // Special case for apostrophes
    .replace(/[^a-ząćęłńóśźż0-9_\-]+/g, "-") // Replace all non-word chars with -
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};
