import React, { useState, useContext } from "react";
import Link from "next/link";
import IcomoonReact from "icomoon-react";
import iconSet from "../../fonts/selection.json";
import DropdownWithLinks from "./DropdownWithLinks";
import LoginForm from "../LoginForm/LoginForm";
import NavbarProfileLinks from "../NavbarProfileLinks/NavbarProfileLinks";
import { UserContext } from "../../context/UserContext";

import * as S from "./NavbarMobile.sc";

const MobileNavbar = () => {
  const [profileOpen, setProfileOpen] = useState(false);
  const [linksOpen, setLinksOpen] = useState(false);
  const [user, setUser] = useContext(UserContext);

  const toggleProfile = () => {
    setLinksOpen(false);
    setProfileOpen(!profileOpen);
  };
  const toggleLinks = () => {
    setProfileOpen(false);
    setLinksOpen(!linksOpen);
  };

  const isActive = profileOpen || linksOpen;
  return (
    <S.Header>
      <S.Nav isActive={isActive}>
        <Link href="/" passHref={true}>
          <a>
            <S.Logo
              src={isActive ? "/images/logo-white.png" : "/images/logo.png"}
            />
          </a>
        </Link>
        <S.NavRow>
          <S.PartnerContainer>
            <S.PartnerText isActive={isActive}>Partnerzy</S.PartnerText>
            <a href="https://www.topagrar.pl/" target="_blank">
              <S.PartnerLogo
                src="/images/logo-tap-nav-gray.png"
                isActive={!isActive}
              />
              <S.PartnerLogo
                src="/images/logo-tap-nav-white.png"
                isActive={isActive}
              />
            </a>
            <a href="https://www.tygodnik-rolniczy.pl/" target="_blank">
              <S.PartnerLogo
                src="/images/logo-tpr-nav-gray.png"
                isActive={!isActive}
              />
              <S.PartnerLogo
                src="/images/logo-tpr-nav-white.png"
                isActive={isActive}
              />
            </a>
          </S.PartnerContainer>
          {process.env.REACT_APP_STAGE !== "1" && (
            <S.IconWithAfter onClick={toggleProfile}>
              <IcomoonReact
                iconSet={iconSet}
                color={isActive ? "#fff" : "#4762AB"}
                size={32}
                icon="user"
              />
            </S.IconWithAfter>
          )}
          <S.Icon onClick={toggleLinks}>
            <IcomoonReact
              iconSet={iconSet}
              color={isActive ? "#fff" : "#4762AB"}
              size={linksOpen ? 28 : 32}
              icon={linksOpen ? "close" : "menu"}
            />
          </S.Icon>
        </S.NavRow>
      </S.Nav>
      {profileOpen && !user.authorized && (
        <S.ListDropdown>
          <LoginForm />
        </S.ListDropdown>
      )}
      {profileOpen && user.authorized && (
        <S.ListDropdown>
          <NavbarProfileLinks />
        </S.ListDropdown>
      )}

      {linksOpen && <DropdownWithLinks />}
    </S.Header>
  );
};

export default MobileNavbar;
