import React from "react";
import * as S from "./Button.sc";

const ButtonHref = ({ type, color, children, customStyle }) => {
  if (type === "gradient") {
    return (
      <S.GradientButtonHref type={type} color={color} customStyle={customStyle}>
        {children}
      </S.GradientButtonHref>
    );
  }
  return (
    <S.StyledButtonHref type={type} color={color} customStyle={customStyle}>
      {children}
    </S.StyledButtonHref>
  );
};

export default ButtonHref;
