import React, { useContext, useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import IcomoonReact from "icomoon-react";
import iconSet from "../../fonts/selection.json";
import { MenuListContext } from "../../context/MenuListContext";
import { MainCategoriesContext } from "../../context/MainCategoriesContext";

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
`;

const linkStyle = `
display: flex;
align-items: center;
justify-content: space-between;
height: 40px;
font-size: 16px;
color: #fff;
text-decoration: none;
`;

const ListItem = styled.a`
  cursor: pointer;
  ${linkStyle}
`;
const ListItemDiv = styled.div`
  border-bottom: ${(props) =>
    props.borderBottom ? "1px solid rgba(255,255,255,0.26)" : "none"};
  margin-bottom: ${(props) => (props.borderBottom ? "20px" : "0")};
  ${linkStyle};
`;
const MenuList = () => {
  const [activeCategory, setActiveCategory] = useState("");
  const { list, submenuLists } = useContext(MenuListContext);
  const categories = useContext(MainCategoriesContext);
  const submenusByName = submenuLists.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {});
  return (
    <div>
      {activeCategory === "Kategorie" ? (
        <List>
          <ListItemDiv onClick={() => setActiveCategory("")} borderBottom>
            {`< wróć do kategorii`}
          </ListItemDiv>
          {categories[0] &&
            categories[0].results.map((category) => (
              <Link
                href={`/artykuly/[category]`}
                as={`/artykuly/${category.slug}`}
                key={category.id}
                passHref={true}
              >
                <ListItem>{category.name}</ListItem>
              </Link>
            ))}
        </List>
      ) : activeCategory ? (
        <List>
          <ListItemDiv onClick={() => setActiveCategory("")} borderBottom>
            {`< wróć do kategorii`}
          </ListItemDiv>
          {submenusByName[activeCategory] &&
            submenusByName[activeCategory].submenu_links.map((item) => (
              <Link href={item.url} key={item.id} passHref={true}>
                <ListItem>{item.label}</ListItem>
              </Link>
            ))}
        </List>
      ) : (
        <List>
          {list.map((el) => {
            const hasSubmenu = Boolean(el.submenu) || el.label === "Kategorie";

            return (
              <li key={el.id}>
                {hasSubmenu ? (
                  <ListItemDiv onClick={() => setActiveCategory(el.label)}>
                    <span>{el.label}</span>
                    <IcomoonReact
                      iconSet={iconSet}
                      color={"#fff"}
                      size={8}
                      icon={"next"}
                    />
                  </ListItemDiv>
                ) : (
                  <Link href={el.url} passHref={true}>
                    <ListItem>
                      <span>{el.label}</span>
                    </ListItem>
                  </Link>
                )}
              </li>
            );
          })}
        </List>
      )}
    </div>
  );
};
export default MenuList;
