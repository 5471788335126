import { useState, useEffect } from "react";
import { fetchApi } from "../services/requests";

export function useFooterPortfolioRequest() {
  let [list, setList] = useState(null);
  let [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi({
        method: "GET",
        origin: "https://aktualizacja.pwr.agro.pl/",
        url: `api/serwer.php?command=get_footer_logos`,
      });

      if (data) {
        setList(data[0].response);
      }
      if (data[1]) {
        setError(data[0].msg);
      }

    };
    getData();

  }, []);

  return [list, error];
}