import styled from 'styled-components';

export const size = {
  mobileLarge: '568px',
  tablet: '768px',
  tabletLarge: '980px',
  desktop: '1240px',
  desktopLarge: '1400px',
};

export const device = {
  mobileLarge: `(min-width: ${size.mobileLarge})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletLarge: `(min-width: ${size.tabletLarge})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopLarge: `(min-width: ${size.desktopLarge})`,
};

export const Page = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media ${device.tabletLarge} {
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;
  @media ${device.tabletLarge} {
    width: calc(100% - 380px);
    margin-bottom: 60px;
    padding: 0;
  }
`;

export const MainContentSlim = styled(MainContent)`
  @media ${device.tabletLarge} {
    max-width: 800px;
  }
`;

export const Aside = styled.aside`
  width: 320px;
  @media ${device.tabletLarge} {
    margin-right: 60px;
  }
  @media print {
    display: none;
  }
`;

export const USER_PROFILE_COLOR = '#456DD6';

export const PageHeader = styled.h2`
  font-size: 30px;
  line-height: 36px;
  font-weight: 900;
  margin: 0 0 20px 0;
`;
