import React, { useContext } from "react";
import Link from "next/link";
import styled from "styled-components";
import { logoutUser } from "../../services/requests";
import { UserContext } from "../../context/UserContext";
import { useRouter } from "next/router";

const List = styled.ul`
  list-style: none;
  padding: 20px;
  margin: 0;
`;
const ListItem = styled.li`
  padding: 10px 0;
  color: #fff;
`;

const NavLink = styled.a`
  font-size: 16px;
  line-height: 20px;
  color: #fff;
`;
const NavbarProfileLinks = () => {
  const [user, setUser] = useContext(UserContext);
  const router = useRouter();
  const logout = async () => {
    const [response] = await logoutUser();
    if (response) {
      setUser({ id: null, token: null, authorized: false });
      if (router.pathname.indexOf("/konto/") !== -1) {
        router.push("/");
      }
    }
  };
  return (
    <List>
      <ListItem>
        <Link href="/konto/profil" passHref={true}>
          <NavLink>Profil</NavLink>
        </Link>
      </ListItem>
      <ListItem>
        <NavLink href="#" onClick={logout}>
          Wyloguj
        </NavLink>
      </ListItem>
    </List>
  );
};

export default NavbarProfileLinks;
