import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import * as S from "./NavbarDesktop.sc";
import IcomoonReact from "icomoon-react";
import iconSet from "../../fonts/selection.json";
import LoginForm from "../LoginForm/LoginForm";
import NavbarProfileLinks from "../NavbarProfileLinks/NavbarProfileLinks";

const Profile = ({ profileOpen, toggleProfile, setProfileOpen }) => {
  const [user, setUser] = useContext(UserContext);
  useEffect(() => {
    if (user.authorized && profileOpen) {
      setProfileOpen(false);
    }
  }, [user]);

  return (
    <S.RelativeContainer>
      <S.Button isActive={profileOpen} onClick={toggleProfile}>
        <IcomoonReact
          iconSet={iconSet}
          color={"#fff"}
          size={24}
          icon={"user"}
        />
        <S.ButtonTextContainer>
          {user.authorized ? (
            <S.ButtonTextLarge>TWÓJ PANEL</S.ButtonTextLarge>
          ) : (
            <>
              <S.ButtonTextLarge>ZALOGUJ SIĘ</S.ButtonTextLarge>
              <S.ButtonTextSmall>ZAREJESTRUJ SIĘ</S.ButtonTextSmall>
            </>
          )}
        </S.ButtonTextContainer>
      </S.Button>

      {profileOpen && !user.authorized && (
        <S.DropdownContainer>
          <LoginForm />
        </S.DropdownContainer>
      )}
      {profileOpen && user.authorized && (
        <S.DropdownContainer>
          <NavbarProfileLinks />
        </S.DropdownContainer>
      )}
    </S.RelativeContainer>
  );
};

export default Profile;
