import styled from "styled-components";

export const Bar = styled.nav`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  max-width: 1240px;
  margin: auto;
  border-bottom: 1px solid #e6e6e6;
  @media print {
    display: none;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  max-width: 100%;
  padding: 0 24px;
  &:after {
    content: "";
    clear: both;
    display: block;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
`;

export const Link = styled.li`
  display: inline-block;
  position: relative;
  padding: 0 24px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 60px;
  cursor: pointer;
  a {
    color: #000;
    text-decoration: none;
  }
`;
export const LinkMore = styled.div`
  position: relative;
  padding: 0 48px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 60px;
  cursor: pointer;
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    left: -48px;
    top: 8px;
    width: 1px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
export const DropdownList = styled.ul`
  position: absolute;
  z-index: 100;
  overflow: hidden;
  right: ${(props) => (props.toLeft ? "auto" : 0)};
  left: ${(props) => (props.toLeft ? 0 : "auto")};
  margin: 0;
  padding: 0;
  min-width: 160px;
  background-color: #f1f1f1;
  list-style: none;
`;

export const DropdownLink = styled.li`
  font-size: 16px;
  line-height: 20px;
  margin: 20px;
  a {
    color: #000;
    text-decoration: none;
  }
`;
