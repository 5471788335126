import react, { useState, useEffect } from "react";
import { fetchApi } from "../services/requests";
import queryString from "query-string";

const defaultParams = {
  site: 12,
  visibility: "site",
  ordering: "-published_at",
};

export function useArticlesRequest(params) {
  let [response, setResponse] = useState(null);
  let [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const query = queryString.stringify({ ...defaultParams, ...params });
      const data = await fetchApi({ url: `articles-article/?${query}` });
      if (data[0]) {
        setResponse(data[0]);
      }
      if (data[1]) {
        setError(data[1]);
      }
    };
    getData();
  }, []);

  return [response, error];
}
