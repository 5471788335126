import React, { useEffect, useState } from "react";
import NavbarMobile from "../NavbarMobile/NavbarMobile";
import NavbarDesktop from "../NavbarDesktop/NavbarDesktop";
import { useCurrentWitdh } from "../../hooks/useCurrentWidth";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const Navbar = () => {
  const [width, setWidth] = useCurrentWitdh(1250);

  if (width > 1240) {
    return <NavbarDesktop />;
  }
  return <NavbarMobile />;
};

export default Navbar;
