import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useRouter } from "next/router";
import { main } from "./AdHelpers";
import styled from "styled-components";
import { usePrevious } from "../../hooks/usePrevious";
import { context } from "./AdoSubscriber";

const Text = styled.span`
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  position: absolute;
  left: 0;
  top: -15px;
`;

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const ContainerChild = styled.div`
  text-align: left;
  width: auto;
  margin: 0 auto;
  position: relative;
`;

const Ado = ({ id, placementId, title, customStyle }) => {
  const adoContext = useContext(context);

  useEffect(() => {
    if (!id || !placementId) return;
    adoContext.subscribe(placementId, id);
    return () => {
      adoContext.unsubscribe(placementId);
    };
  }, [placementId, adoContext]);

  const renderText =
    typeof title === "string" ? (
      <Text className="title--additional">{title}</Text>
    ) : (
      <Text className="title--additional">
        r&nbsp;e&nbsp;k&nbsp;l&nbsp;a&nbsp;m&nbsp;a
      </Text>
    );
  if (!id || !placementId) {
    return null;
  }
  return (
    <Container className="container" style={customStyle}>
      <ContainerChild>
        <div
          id={id}
          style={{ maxWidth: 940, margin: "35px 0 20px 0", ...customStyle }}
        ></div>
        {renderText}
      </ContainerChild>
    </Container>
  );
};

export default Ado;
