import React, { useState, useEffect, useContext, useRef } from "react";
import Link from "next/link";
import IcomoonReact from "icomoon-react";
import iconSet from "../../fonts/selection.json";
import * as S from "./MenuList.sc";
import { useCurrentWitdh } from "../../hooks/useCurrentWidth";
import { MenuListContext } from "../../context/MenuListContext";
import { MainCategoriesContext } from "../../context/MainCategoriesContext";

const MenuList = () => {
  const { list, submenuLists } = useContext(MenuListContext);
  const categories = useContext(MainCategoriesContext);
  const [menuList, setMenuList] = useState([]);
  const [more, setMore] = useState([]);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [showNavDropdown, setShowNavDropdown] = useState(null);

  const width = useCurrentWitdh();
  const refNavbar = useRef(null);
  const refNavbarContainer = useRef(null);

  useEffect(() => {
    setMenuList(list);
  }, [list]);
  useEffect(() => {
    buildNav();
  }, [width, menuList]);

  const buildNav = () => {
    const needMoveLink = needMoveLinkToDropdown();
    needMoveLink && addLinkToDropdown();
  };

  const needMoveLinkToDropdown = () => {
    const container =
      refNavbarContainer.current && refNavbarContainer.current.clientWidth - 40;
    const navbar = refNavbar.current && refNavbar.current.clientWidth;
    return container < navbar ? true : false;
  };

  const addLinkToDropdown = () => {
    const tempList = [...menuList];
    const items = tempList.slice(0, -1);
    const item = tempList.slice(-1)[0];
    if (!item) {
      return;
    }
    const moreCopy = [...more];

    setMenuList(items);
    if (!moreCopy.find((el) => el.id === item.id)) {
      setMore([...moreCopy, item]);
    }
  };
  const dropdownStyle = (visible) => ({
    height: visible ? "auto" : "0",
  });

  const categoryDropdown = (id) => (
    <S.DropdownList toLeft style={dropdownStyle(showNavDropdown === id)}>
      {categories[0] &&
        categories[0].results
          .filter((el) => el.show_on_search_results === false)
          .map((category) => (
            <S.DropdownLink key={`navCat${category.id}`}>
              <Link
                href={`/artykuly/[category]`}
                as={`/artykuly/${category.slug}`}
                passHref={true}
              >
                <a>{category.name}</a>
              </Link>
            </S.DropdownLink>
          ))}
    </S.DropdownList>
  );

  const submenuDropdown = (id) => {
    const activeSubmenu = submenuLists.find((el) => el.id === id);
    return (
      <S.DropdownList toLeft style={dropdownStyle(showNavDropdown === id)}>
        {activeSubmenu &&
          activeSubmenu.submenu_links &&
          activeSubmenu.submenu_links.map((submenuLink) => (
            <S.DropdownLink key={submenuLink.url}>
              <Link href={submenuLink.url} passHref={true}>
                <a>{submenuLink.label}</a>
              </Link>
            </S.DropdownLink>
          ))}
      </S.DropdownList>
    );
  };

  const setHoverEvents = (id) => ({
    onMouseEnter: () => setShowNavDropdown(id),
    onMouseLeave: () => setShowNavDropdown(null),
  });
  return (
    <S.Bar>
      <S.ListContainer ref={refNavbarContainer}>
        <S.List ref={refNavbar}>
          {menuList.map((el) => {
            const hasDropdown = el.submenu || el.label === "Kategorie";
            return (
              <S.Link
                key={`menulist${el.id}`}
                {...(hasDropdown
                  ? setHoverEvents(
                      el.label === "Kategorie" ? "categories" : el.submenu
                    )
                  : {})}
              >
                <Link href={el.url} passHref={true}>
                  <a>
                    {el.label}
                    {hasDropdown && (
                      <IcomoonReact
                        iconSet={iconSet}
                        color={"#000"}
                        icon={"next"}
                        size={7}
                        style={{
                          marginLeft: "7px",
                          transform: "rotate(90deg)",
                        }}
                      />
                    )}
                  </a>
                </Link>
                {el.label === "Kategorie" && categoryDropdown("categories")}
                {el.submenu && submenuDropdown(el.submenu)}
              </S.Link>
            );
          })}
        </S.List>
      </S.ListContainer>
      {more.length ? (
        <S.LinkMore onClick={() => setShowMoreDropdown(!showMoreDropdown)}>
          Więcej
          <IcomoonReact
            iconSet={iconSet}
            color={"#000"}
            size={24}
            icon="menu-dots"
            style={{ marginLeft: "6px" }}
          />
          <S.DropdownList style={dropdownStyle(showMoreDropdown)}>
            {more.map((el) => (
              <S.DropdownLink key={`more${el.id}`}>
                <Link href={el.url} passHref={true}>
                  <a>{el.label}</a>
                </Link>
              </S.DropdownLink>
            ))}
          </S.DropdownList>
        </S.LinkMore>
      ) : null}
    </S.Bar>
  );
};
export default MenuList;
