import styled from "styled-components";

export const HeaderNav = styled.header`
  width: 100%;
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  max-width: 1240px;
  padding: 0 20px;
  margin: auto;
  border-bottom: 1px solid #e6e6e6;
  background-color: "#fff";
`;
export const NavTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 220px);
  max-width: 980px;
  @media print {
    display: none;
  }
`;

export const Logo = styled.img`
  max-width: 200px;
  max-height: 140px;
  width: auto;
  align-self: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 160px;
  border: none;
  border-radius: ${(props) => (props.isActive ? "8px 8px 0 0" : "8px")};
  margin-left: 20px;
  color: #fff;
  background-color: ${(props) => (props.isActive ? "#1D3A8D" : "#339D37")};
  cursor: pointer;
`;

export const ButtonTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
`;

export const ButtonTextLarge = styled.span`
  font-size: 18px;
  line-height: 1;
  font-weight: 900;
  white-space: nowrap;
`;
export const ButtonTextSmall = styled.span`
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 58px;
  right: 0;
  z-index: 100;
  background: transparent linear-gradient(144deg, #314e9c 0%, #4762ab 100%) 0%
    0% no-repeat border-box;
  width: 340px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px 0 8px 8px;
`;

export const PartnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 160px;
`;

export const PartnerText = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #000;
  width: 100%;
  text-align: center;
`;

export const PartnerLogo = styled.img`
  max-width: 100%;
  width: 80px;
`;
