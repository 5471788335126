import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.div`
  position: relative;
  z-index: 10;
  display: block;
  margin: 10px 10px 20px 10px;
  max-width: 320px;
  padding: 0;
  cursor: pointer;
  @media ${device.desktop} {
    max-width: 300px;
    margin-bottom: 34px;
  }
`;

export const Category = styled.span`
  display: inline-block;
  padding: 3px 5px;
  border-radius: 4px;
  background-color: ${(props) => `${props.color}`};
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 900;
`;

export const Title = styled.h3`
  position: relative;
  margin: 10px 0 22px 0;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  @media ${device.desktop} {
    margin-bottom: 10px;
  }
  &::after {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    background-color: ${(props) => props.color};
    position: absolute;
    bottom: -14px;
  }
`;
