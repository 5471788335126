import styled from "styled-components";
import { device } from "../../services/styles";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 11px;
  margin: 20px 0;
  width: 100%;
  height: 44px;
  background-color: #f1f1f1;
  display: none;
  cursor: pointer;
  @media ${device.desktop} {
    display: block;
  }
`;

export const TextRow = styled.div`
  height: 21px;
  overflow: hidden;
`;

export const Important = styled.span`
  display: inline-block;
  float: left;
  padding: 4px;
  margin-right: 10px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  background-color: #e6141b;
  color: #fff;
`;

export const Title = styled.span`
  height: 21px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 21px;
  color: #777777;
`;
