import React, { useState } from "react";
import Link from "next/link";
import * as S from "./NavbarDesktop.sc";
import SearchForm from "../SearchForm/SearchForm";
import SocialIcons from "../SocialIcons/SocialIcons";
import MenuList from "../MenuList/MenuList";
import Profile from "./Profile";

const NavbarDesktop = () => {
  const [profileOpen, setProfileOpen] = useState(false);
  const [linksOpen, setLinksOpen] = useState(false);

  const toggleProfile = () => {
    setLinksOpen(false);
    setProfileOpen(!profileOpen);
  };
  const toggleLinks = () => {
    setProfileOpen(false);
    setLinksOpen(!linksOpen);
  };

  const isActive = profileOpen || linksOpen;
  return (
    <S.HeaderNav>
      <S.Nav isActive={isActive}>
        <Link href="/" passHref={true}>
          <a>
            <S.Logo src={"/images/logo.png"} />
          </a>
        </Link>
        <S.NavTools>
          <S.PartnerContainer>
            <S.PartnerText>Partnerzy</S.PartnerText>
            <a href="https://www.topagrar.pl/" target="_blank">
              <S.PartnerLogo src="/images/logo-tap-nav-gray.png" />
            </a>
            <a href="https://www.tygodnik-rolniczy.pl/" target="_blank">
              <S.PartnerLogo src="/images/logo-tpr-nav-gray.png" />
            </a>
          </S.PartnerContainer>
          {/* <SearchForm isMobile={false} /> */}

          <SocialIcons />
          <Profile
            profileOpen={profileOpen}
            toggleProfile={toggleProfile}
            setProfileOpen={setProfileOpen}
          />
        </S.NavTools>
      </S.Nav>
      <MenuList />
    </S.HeaderNav>
  );
};
export default NavbarDesktop;
