import React, { useState, useContext, useRef } from "react";
import Link from "next/link";
import ReCAPTCHA from "react-google-recaptcha";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Button from "../Button/Button";
import ButtonHref from "../Button/ButtonHref";
import { loginUser } from "../../services/requests";
import { UserContext } from "../../context/UserContext";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Label = styled.label`
  padding: 0 0 5px 10px;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 600;
`;

const Input = styled.input`
  margin-bottom: 20px;
  height: 48px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 0 10px;
`;

const Error = styled.span`
  font-size: 16px;
  color: #fff;
  padding: 5px;
  border-radius: 8px;
  background-color: #e6141b;
  margin: -15px 0 10px 0;
`;

const RecaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LoginForm = () => {
  const recaptchaRef = useRef(null);
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const [user, setUser] = useContext(UserContext);
  const [submitError, setSubmitError] = useState(null);
  const router = useRouter();

  const onSubmit = async (formData) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue === "") {
      setSubmitError(`zaznacz "nie jestem robotem"`);
      return;
    }
    const [data, error, status] = await loginUser(formData);
    if (data && data.token) {
      setUser({ ...user, token: data.token, authorized: true });
      setValue("username", "");
      setValue("password", "");
      if (router.query.uidb64) {
        router.push("/konto/profil");
      }
    }
    if (status.status === 400) {
      setSubmitError(error.non_field_errors);
    } else if (status.status === 500) {
      setSubmitError(
        "Coś poszło nie tak. Odśwież stronę lub spróbuj ponownie poźniej."
      );
    }
  };

  const removeSubmitError = () => {
    setSubmitError(null);
  };

  return (
    <Form>
      <Label htmlFor="username">Login</Label>
      <Input
        name="username"
        id="username"
        ref={register({ required: true })}
        onChange={removeSubmitError}
      />
      {errors.username && <Error>To pole jest wymagane</Error>}
      <Label htmlFor="password">Hasło</Label>
      <Input
        name="password"
        id="password"
        type="password"
        ref={register({ required: true })}
        onChange={removeSubmitError}
      />
      {errors.password && <Error>To pole jest wymagane</Error>}
      <RecaptchaContainer>
        <ReCAPTCHA
          sitekey={process.env.GOOGLE_RECAPTCHA_SECRET_KEY}
          onChange={removeSubmitError}
          ref={recaptchaRef}
        />
      </RecaptchaContainer>
      <Button
        color="green"
        onClickFunction={handleSubmit(onSubmit)}
        customStyle={{ margin: "20px 0 20px 0" }}
      >
        Zaloguj się
      </Button>
      {submitError && <Error>{submitError}</Error>}
      <Link href="/konto/rejestracja" passHref={true}>
        <a>
          <ButtonHref
            color="white"
            type={"border"}
            customStyle={{ marginTop: 0, width: "100%" }}
          >
            Zarejestruj się
          </ButtonHref>
        </a>
      </Link>
    </Form>
  );
};

export default LoginForm;
