import React, { useState, useEffect } from "react";
import * as S from "./Partners.sc";
import { fetchApi } from "../../services/requests";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi({
        url: "blocks-partner/?id=&site=12",
      });
      if (data[0]) {
        setPartners(data[0].results);
      }
    };
    getData();
  }, []);
  if (partners.length) {
    return (
      <S.Container>
        <S.Header>Partnerzy portalu</S.Header>
        <S.Row>
          {partners.map((partner) => (
            <a href={partner.url} target="_blank" key={partner.pk}>
              <S.Partner src={partner.logo} />
            </a>
          ))}
        </S.Row>
      </S.Container>
    );
  }
  return <div></div>;
};

export default Partners;
