import React from "react";
import styled from "styled-components";
import IcomoonReact from "icomoon-react";
import slugify from "slugify";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import iconSet from "../../fonts/selection.json";
import { buildSlug } from "../../services/urlString";

const Form = styled.form`
  position: relative;
  width: 100%;
  max-width: 351px;
  margin: 0;
`;

const Input = styled.input`
  height: ${(props) => (props.isMobile ? "40px" : "56px")};
  width: 100%;
  padding: ${(props) => (props.isMobile ? "0 10px" : "0 20px")};

  border-radius: 8px;
  border: none;
  background-color: ${(props) => (props.isMobile ? "#fff" : "#F1F1F1")};
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  &:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Button = styled.button(({ isMobile }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  padding: 0,
  height: isMobile ? "40px" : "56px",
  width: isMobile ? "40px" : "56px",
  borderRadius: "0 8px 8px 0",
  border: "none",
  backgroundColor: isMobile ? "#339D37" : "#456DD6",
}));

const SearchForm = ({ isMobile }) => {
  const { register, handleSubmit } = useForm();
  const router = useRouter();
  const submitSearch = (data) => {
    const slug = buildSlug(data.search).toLocaleLowerCase();
    router.push(`/szukaj?szukaj=${slug}`);
  };
  return (
    <Form isMobile={isMobile}>
      <Input
        type="text"
        name="search"
        isMobile={isMobile}
        placeholder={
          isMobile
            ? "Znajdź rozwiązanie"
            : "Znajdź rozwiązanie Swojego problemu"
        }
        ref={register}
      />
      <Button
        type="submit"
        isMobile={isMobile}
        onClick={handleSubmit(submitSearch)}
      >
        <IcomoonReact iconSet={iconSet} color="#fff" size={20} icon="search" />
      </Button>
    </Form>
  );
};
export default SearchForm;
