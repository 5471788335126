import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.div`
  @media print {
    display: none;
  }
`;

export const Header = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  margin: 50px 0 30px 0;
  width: 100%;
  text-align: center;
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 32px;
    margin: 80px 0 20px 0;
  }
`;

export const Row = styled.div`
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const Partner = styled.img`
  margin-bottom: 20px;
`;
